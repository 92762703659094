import React from 'react';

import { Error404 } from '@/components/pages';
import generateMetaTags from '@/utils/meta';

import type { GetStaticProps, NextPage } from 'next';
import { getSiteSettings, SiteSettingsType } from '@/api';

type Props = {
  siteSettings: SiteSettingsType;
};

const Custom404: NextPage<Props> = ({ ...rest }) => {
  return (
    <>
      {generateMetaTags({
        title: 'Page Not Found',
        description: 'Sorry, we couldn’t find the page you are looking for.',
      })}
      <Error404 {...rest} />
    </>
  );
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  const siteSettings = await getSiteSettings();

  return {
    props: {
      siteSettings,
    },
  };

  return {
    notFound: true,
  };
};

export default Custom404;
