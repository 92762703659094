const Error404Resources = {
  heading: 'Browse other pages:',
  linksCollection: {
    items: [
      {
        linkName: 'Info about separating',
        linkDescription:
          'This section has info about every aspect of separation, from family violence to culturally appropriate services for Aboriginal Australians.',
        link: '/info-about-separating',
      },
      {
        linkName: 'Help and support',
        linkDescription:
          'Separating can be an emotional time. At the same time you need to think about practical matters like arrangements for children, and dividing your money and property.',
        link: '/help-and-support',
      },
      {
        linkName: 'Find out if amica is right for you',
        linkDescription:
          'Work through five questions to help you decide if amica is right for your situation.',
        link: '/is-amica-right-for-me',
      },
      {
        linkName: 'How amica works',
        linkDescription:
          'Use amica to separate from your partner, make parenting arrangements if you have children and divide property and money.',
        link: '/how-it-works',
      },
    ],
  },
};

export default Error404Resources;
