import React from 'react';
import { Flex, Box, Text, Container, Stack } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import { Page } from '@/components/layout';
import { SectionResources } from '@/components/section';
import { Breadcrumbs } from '@/components/ui';
import { SiteSettingsType } from '@/api';
import Error404Resources from './Error404Resources';
import { StandardTemplate } from '../Standard/Standard';

type FourOFourProps = {
  siteSettings: SiteSettingsType;
} & FlexProps;

const Error404: React.FC<FourOFourProps> = ({ ...rest }) => {
  const FourOFourSettings = {
    ...rest.siteSettings,
    mainBottomBanner: undefined,
  };

  return (
    <StandardTemplate
      siteSettings={FourOFourSettings}
      theme="grey"
      hasHeroContent={true}
      hero={
        <Container maxW="container.xl">
          <Breadcrumbs
            paddingTop={16}
            marginBottom={{ base: 10, md: 18, lg: 24 }}
            notFound
          />
          <Text textStyle="tite">This page no longer exists</Text>
        </Container>
      }
      content={<SectionResources {...Error404Resources} />}
    />
  );
};

export default Error404;
